/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import BrowsboxPageEditModal, {
  EDIT_PAGE_VIEW,
  DUPLICATE_PAGE_VIEW,
  NEW_PAGE_VIEW,
} from './PageEditModal';
import { pagePropsPropType, pagePropsDefaultProps } from './PagePropTypes';
import { loadPages, pageReorder, pagesReorder } from '../../actions/pages';
import { deletePage, PAGE_DELETE_SUCCESS } from '../../actions/pageDelete';
import { unrestrictPage, restrictPage } from '../../actions/pageRestrict';
import { unpublishPage, publishPage } from '../../actions/pagePublish';
import { closeDuplicatePage, duplicatePage, openDuplicatePage } from '../../actions/pageDuplicate';
import { closeNewPage, newPage } from '../../actions/pageNew';
import {
  loadPageProps,
  updatePageProps,
  openPageProperties,
  closePageProperties,
} from '../../actions/pageProperties';
import {
  getPageHeaderHtml,
} from '../../actions/pageHtml';
import ConfirmationModal from '../Modals/ConfirmationModal';
import i18n from '../../internationalization/i18n';
import PageList from './PageList';
import PageContext from './PageContext';

const propTypes = {
  closeDuplicatePage: PropTypes.func.isRequired,
  closeNewPage: PropTypes.func.isRequired,
  closePageProperties: PropTypes.func.isRequired,
  deletePage: PropTypes.func.isRequired,
  duplicatePage: PropTypes.func.isRequired,
  openDuplicatePage: PropTypes.func.isRequired,
  duplicatePageId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  getPageHeaderHtml: PropTypes.func.isRequired,
  isDuplicatePageModalOpen: PropTypes.bool,
  isNewPageModalOpen: PropTypes.bool,
  isPagePropsModalOpen: PropTypes.bool,
  loadPageProps: PropTypes.func.isRequired,
  loadPages: PropTypes.func.isRequired,
  newPage: PropTypes.func.isRequired,
  openPageProperties: PropTypes.func.isRequired,
  pageItems: PropTypes.array.isRequired,
  pageProps: pagePropsPropType,
  pageReorder: PropTypes.func.isRequired,
  pages: PropTypes.array.isRequired,
  pagesReorder: PropTypes.func.isRequired,
  publishPage: PropTypes.func.isRequired,
  restrictPage: PropTypes.func.isRequired,
  unpublishPage: PropTypes.func.isRequired,
  unrestrictPage: PropTypes.func.isRequired,
  updatePageProps: PropTypes.func.isRequired,
  isModified: PropTypes.bool.isRequired,
  languages: PropTypes.array.isRequired,
  currentPage: PropTypes.object.isRequired,
};

const defaultProps = {
  isPagePropsModalOpen: false,
  isDuplicatePageModalOpen: false,
  isNewPageModalOpen: false,
  duplicatePageId: false,
  pageProps: {},
};

const goToUrl = (url) => {
  window.location.href = url;
};

class BrowsboxPage extends Component {
  static contextType = PageContext;

  constructor(props) {
    super(props);
    this.onCloseDuplicatePageModal = this.onCloseDuplicatePageModal.bind(this);
    this.onCloseNewPageModal = this.onCloseNewPageModal.bind(this);
    this.onCloseProperties = this.onCloseProperties.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onDuplicatePage = this.onDuplicatePage.bind(this);
    this.onNewPage = this.onNewPage.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.onDuplicate = this.onDuplicate.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onRestrict = this.onRestrict.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.onUpdateProperties = this.onUpdateProperties.bind(this);
    this.showProperties = this.showProperties.bind(this);
    this.getHeader = this.getHeader.bind(this);

    this.state = {
      messages: [],
      queryDeletePage: false,
      refreshPageUrl: null,
      confirmedUnsavedChanges: false,
    };
  }

  componentDidMount() {
    this.getPageList();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.pageItems && this.props.pageItems !== prevProps.pageItems) {
      this.rerenderPageList();
    }
  }

  onPublish(pageItem, value) {
    if (value) {
      this.props.publishPage(pageItem.id)
        .then(this.props.loadPages);
    } else {
      this.props.unpublishPage(pageItem.id)
        .then(this.props.loadPages);
    }
  }

  onDuplicate(pageItem) {
    this.props.openDuplicatePage(pageItem.id);
  }

  onDelete(pageItem) {
    this.setState({ queryDeletePage: pageItem });
  }

  // On confirmation of delete section/component modal
  onDeleteConfirmation() {
    const { queryDeletePage } = this.state;
    if (queryDeletePage === false) { return; }
    const { id, route } = queryDeletePage;     // eslint-disable-line
    this.setState({ queryDeletePage: false }, () => {
      this.props.deletePage({ id })
        .then((action) => {
          if (action.type === PAGE_DELETE_SUCCESS) {
            this.rerenderPageList(id, route);
          }
        });
    });
  }

  onReorder(pages, pageOrder, items) {
    // update redux store
    this.props.pageReorder({ pages, pageOrder });

    // push to api
    const mapToApiData = page => [page.id, page.children.map(mapToApiData)];
    const apiData = items.map(mapToApiData);
    const { currentPage } = this.props;

    this.props.pagesReorder({ pages: apiData })
      .then(() => {
        this.rerenderPageList(currentPage.id, currentPage.route, false);
      });
  }

  onRestrict(pageItem, value) {
    if (value) {
      this.props.restrictPage(pageItem.id)
        .then(this.props.loadPages);
    } else {
      this.props.unrestrictPage(pageItem.id)
        .then(this.props.loadPages);
    }
  }

  onCloseProperties() {
    this.props.closePageProperties();
    this.clearState();
  }

  onUpdateProperties(value) {
    this.props.updatePageProps(value)
      .then((response) => {
        if (response.error) {
          this.setMessages(response.error);
        } else {
          this.clearState();
          this.props.closePageProperties();
          this.rerenderPageList(value.id, value.route);
        }
      });
  }

  onCloseDuplicatePageModal() {
    this.props.closeDuplicatePage();
    this.clearState();
  }

  onCloseNewPageModal() {
    this.props.closeNewPage();
    this.clearState();
  }

  onDuplicatePage(value) {
    this.props.duplicatePage(value)
      .then((action) => {
        if (action.error) {
          this.setMessages(action.error);
        } else {
          const { response } = action;
          const { route } = response;
          this.clearState();
          this.props.closeDuplicatePage();
          goToUrl(`${route}?tab=modules`);
        }
      });
  }

  onNewPage(value) {
    // match api endpoint requirements
    const {
      name: title, visible, published, description, keywords, pagetitle, isRedirect, redirectUrl,
    } = value;
    const page = {
      title,
      visible,
      published,
      page: {
        title: pagetitle,
        description,
        keywords,
      },
      isRedirect,
      redirectUrl,
    };
    this.props.newPage(page)
      .then((response) => {
        const { error, response: data } = response;
        if (error) {
          this.setMessages(error);
        } else {
          this.clearState();
          this.props.closeNewPage();
          // Redirect window to new location
          if (data && data.route) {
            this.context.redirect(`${data.route}?tab=modules`);
          }
        }
      });
  }

  setMessages(messages) {
    if (messages) {
      this.setState({
        messages: typeof messages === 'string' ? [{ message: messages }] : [messages],
      });
    } else {
      this.setState({
        messages: [],
      });
    }
  }

  getPageList() {
    return this.props.loadPages();
  }

  getHeader() {
    this.props.getPageHeaderHtml();
  }

  getHomePage() {
    const activeLanguage = this.props.languages.filter(language => language.active)[0];
    const homePageUrl = (activeLanguage.default ? '/' : `/${activeLanguage.code}`);
    return this.props.pages.filter(page => page.route === homePageUrl)[0];
  }

  redirectIfNeeded(id, route) {
    // get the updated (current) page
    const { currentPage } = this.props;
    const updatedPage = this.props.pages.filter(page => (page.id === id))[0];

    // check if the route of the current updated page has been changed
    if (updatedPage) {
      // check if the route has actually changed (because for example a reordering of position keeps the same URL)
      if (updatedPage.route !== route) {
        if (this.props.isModified) {
          // the `refreshPageUrl` opens the modal to confirm page load and losing changes
          this.setState({ refreshPageUrl: updatedPage.route });
          return;
        }

        if (updatedPage.id === currentPage.id) {
          goToUrl(updatedPage.route);
          return;
        }

        if (Array.from(updatedPage.children).includes(currentPage.id)) {
          const childPage = this.props.pages.filter(page => (page.id === currentPage.id))[0];
          if (childPage) {
            goToUrl(childPage.route);
          }
        }
      }
    } else {
      // if the route is not found anymore (e.g. when deleted) redirect to home page
      const homePage = this.getHomePage();

      if (homePage) {
        goToUrl(homePage.route);
      }
    }
  }

  /**
   * Rerender the page list. Also check if a redirect is needed (e.g. if current page is removed,
   * or has it's page name (route) changed or is drag'n'drop-ed (so route changed))
   *
   * @param {int} pageId        The ID of the page that is changed/removed
   * @param {string} pageRoute  The route of the page that is changed/removed
   */
  rerenderPageList(pageId = null, pageRoute = null) {
    this.getHeader();
    this.getPageList()
      .then(() => {
        if (pageId && pageRoute) {
          this.redirectIfNeeded(pageId, pageRoute);
        }
      });
  }

  clearState() {
    this.setState({
      messages: [],
      confirmedUnsavedChanges: false,
    });
  }

  showProperties(pageItem) {
    const { id } = pageItem;
    this.props.openPageProperties(id);
    this.props.loadPageProps(id);
  }

  renderModal() {
    if (this.props.isPagePropsModalOpen) {
      return (
        <BrowsboxPageEditModal
          messages={this.state.messages}
          onClose={this.onCloseProperties}
          onUpdate={this.onUpdateProperties}
          pageProps={this.props.pageProps}
          title={i18n.t('CONTENT.updatePage')}
          view={EDIT_PAGE_VIEW}
        />
      );
    } if (this.props.isDuplicatePageModalOpen) {
      if (this.props.isModified && !this.state.confirmedUnsavedChanges) {
        return (
          <ConfirmationModal
            icon="icon-basic_elaboration_document_refresh"
            onCancel={this.onCloseDuplicatePageModal}
            onClose={this.onCloseDuplicatePageModal}
            onOk={() => this.setState({
              confirmedUnsavedChanges: true,
            })}
            title={i18n.t('CONTENT.refreshPageModalTitle')}
            submitButtonText={i18n.t('CONTENT.continue')}
          >
            <div dangerouslySetInnerHTML={{ __html: i18n.t('CONTENT.refreshPageModalQuestion') }} />
          </ConfirmationModal>
        );
      }

      const duplicatePageProps = {
        id: false,
        duplicateId: this.props.duplicatePageId,
        ...pagePropsDefaultProps,
      };
      return (
        <BrowsboxPageEditModal
          messages={this.state.messages}
          onClose={this.onCloseDuplicatePageModal}
          onUpdate={this.onDuplicatePage}
          pageProps={duplicatePageProps}
          title={i18n.t('CONTENT.duplicatePage')}
          view={DUPLICATE_PAGE_VIEW}
        />
      );
    } if (this.props.isNewPageModalOpen) {
      const newPageProps = { id: false, ...pagePropsDefaultProps };
      return (
        <BrowsboxPageEditModal
          messages={this.state.messages}
          onClose={this.onCloseNewPageModal}
          onUpdate={this.onNewPage}
          pageProps={newPageProps}
          title={i18n.t('CONTENT.newPage')}
          view={NEW_PAGE_VIEW}
        />
      );
    } if (this.state.queryDeletePage !== false) {
      const { queryDeletePage: { title } } = this.state;
      const onCancel = () => {
        this.setState({ queryDeletePage: false });
      };
      return (
        <ConfirmationModal
          icon="icon-basic_sheet"
          onCancel={onCancel}
          onClose={onCancel}
          onOk={this.onDeleteConfirmation}
          submitButtonText={i18n.t('CONTENT.delete')}
          title={i18n.t('CONTENT.deletePage')}
        >
          <div>
            <div>
              {i18n.t('CONTENT.deletePageQuery')}
            </div>
            <b>{title}</b>
          </div>
        </ConfirmationModal>
      );
    } if (this.state.refreshPageUrl !== null) {
      const onCancel = () => {
        this.setState({ refreshPageUrl: null });
      };
      return (
        <ConfirmationModal
          icon="icon-basic_elaboration_document_refresh"
          onCancel={onCancel}
          onClose={onCancel}
          onOk={() => { BrowsboxPage.goToUrl(this.state.refreshPageUrl); }}
          title={i18n.t('CONTENT.refreshPageModalTitle')}
          submitButtonText={i18n.t('CONTENT.continue')}
        >
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONTENT.refreshPageModalQuestion') }} />
        </ConfirmationModal>
      );
    }
    return null;
  }

  render() {
    const {
      pageItems,
    } = this.props;

    return (
      <>
        <PageList
          pageItems={pageItems}
          showProperties={this.showProperties}
          onReorder={this.onReorder}
          onDelete={this.onDelete}
          onDuplicate={this.onDuplicate}
        />
        { this.renderModal() }
      </>
    );
  }
}

BrowsboxPage.propTypes = propTypes;
BrowsboxPage.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    entities: {
      pages,
      pageOrder,
      pageProps,
      languages,
    },
    content: {
      isModified,
    },
    pages: {
      currentPage,
    },
  } = state;

  // Orderd root level pages as array
  const pageItems = pageOrder.map(m => pages[m]);
  // All pages as array
  const allPages = Object.keys(pages).map(m => pages[m]);
  const pageProp = pageProps ? pageProps[Object.keys(pageProps)[0]] : pageProps;
  if (pageProp && pageProp.page) {
    pageProp.pagetitle = pageProp.page.title;
    pageProp.description = pageProp.page.description;
    pageProp.keywords = pageProp.page.keywords;
    pageProp.name = pageProp.title;
    delete pageProp.page;
  }

  return {
    pageItems,
    pages: allPages,
    isPagePropsModalOpen: state.pages.isPagePropsModalOpen,
    isDuplicatePageModalOpen: state.pages.isDuplicatePageModalOpen,
    isNewPageModalOpen: state.pages.isNewPageModalOpen,
    duplicatePageId: state.pages.duplicatePageId,
    pageProps: pageProp,
    isModified,
    languages,
    currentPage,
  };
};

const mapDispatchToProps = {
  closeDuplicatePage,
  closeNewPage,
  closePageProperties,
  deletePage,
  openDuplicatePage,
  duplicatePage,
  getPageHeaderHtml,
  loadPageProps,
  loadPages,
  newPage,
  openPageProperties,
  pageReorder,
  pagesReorder,
  publishPage,
  restrictPage,
  unpublishPage,
  unrestrictPage,
  updatePageProps,
};

const BrowsboxPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxPage);

export default withNamespaces()(BrowsboxPageContainer);
