import { CALL_BB_API, buildRoute } from '../api';
import { PAGE_PROPS_SCHEMA } from '../api/schema';
import { ROUTE_PAGE_ITEM } from '../api/routes';

export const PAGE_PROPS_REQUEST = 'PAGE_PROPS_REQUEST';
export const PAGE_PROPS_UPDATE = 'PAGE_PROPS_UPDATE';
export const PAGE_PROPS_SUCCESS = 'PAGE_PROPS_SUCCESS';
export const PAGE_PROPS_FAILURE = 'PAGE_PROPS_FAILURE';

// BrowsBox API middleware.
const fetchPageProps = (id) => {
  const endpoint = buildRoute(ROUTE_PAGE_ITEM, { id });
  return {
    [CALL_BB_API]: {
      types: [PAGE_PROPS_REQUEST, PAGE_PROPS_SUCCESS, PAGE_PROPS_FAILURE],
      endpoint,
      method: 'GET',
      schema: PAGE_PROPS_SCHEMA,
    },
  };
};

// BrowsBox API middleware.
const updatePagePropsCall = (value) => {
  const { id } = value;
  const endpoint = buildRoute(ROUTE_PAGE_ITEM, { id });
  const json = {
    title: value.name,
    published: value.published,
    visible: value.visible,
    page: {
      title: value.pagetitle,
      description: value.description,
      keywords: value.keywords,
    },
    isRedirect: value.isRedirect,
    redirectUrl: value.redirectUrl,
  };
  return {
    [CALL_BB_API]: {
      types: [PAGE_PROPS_UPDATE, PAGE_PROPS_SUCCESS, PAGE_PROPS_FAILURE],
      endpoint,
      method: 'PUT',
      json,
    },
  };
};

// Redux Thunk middleware.
export const loadPageProps = id => dispatch => dispatch(fetchPageProps(id));
export const updatePageProps = value => dispatch => dispatch(updatePagePropsCall(value));

export const OPEN_PAGE_PROPERTIES = 'OPEN_PAGE_PROPERTIES';
export const CLOSE_PAGE_PROPERTIES = 'CLOSE_PAGE_PROPERTIES';
export const UPDATE_PAGE_PROPERTIES = 'UPDATE_PAGE_PROPERTIES';
export const openPageProperties = id => ({ type: OPEN_PAGE_PROPERTIES, id });
export const closePageProperties = () => ({ type: CLOSE_PAGE_PROPERTIES });
