import { CALL_BB_API, buildRoute } from '../api';
import { ROUTE_PAGE_DUPLICATE } from '../api/routes';

export const PAGE_DUPLICATE_REQUEST = 'PAGE_DUPLICATE_REQUEST';
export const PAGE_DUPLICATE_SUCCESS = 'PAGE_DUPLICATE_SUCCESS';
export const PAGE_DUPLICATE_FAILURE = 'PAGE_DUPLICATE_FAILURE';

// BrowsBox API middleware.
const postDuplicatePage = (value) => {
  const { duplicateId: id } = value;
  const endpoint = buildRoute(ROUTE_PAGE_DUPLICATE, { id });
  const json = {
    title: value.name,
    published: value.published,
    visible: value.visible,
    page: {
      title: value.pagetitle,
      description: value.description,
      keywords: value.keywords,
    },
    isRedirect: value.isRedirect,
    redirectUrl: value.redirectUrl,
  };
  return {
    [CALL_BB_API]: {
      types: [PAGE_DUPLICATE_REQUEST, PAGE_DUPLICATE_SUCCESS, PAGE_DUPLICATE_FAILURE],
      endpoint,
      method: 'POST',
      json,
    },
  };
};

// Redux Thunk middleware.
export const duplicatePage = value => dispatch => dispatch(
  postDuplicatePage(value),
);

export const OPEN_DUPLICATE_PAGE = 'OPEN_DUPLICATE_PAGE';
export const CLOSE_DUPLICATE_PAGE = 'CLOSE_DUPLICATE_PAGE';

// Redux Thunk middleware.
export const openDuplicatePage = id => ({ type: OPEN_DUPLICATE_PAGE, id });
export const closeDuplicatePage = () => ({ type: CLOSE_DUPLICATE_PAGE });
